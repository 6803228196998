import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { CarouselProvider, Slider, Slide, DotGroup, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

export const Carousel = () => {
  const {
    allFile: { nodes: images },
  } = useStaticQuery(graphql`
    {
      allFile(sort: { fields: name }, filter: { dir: { regex: "/vyroba/" } }) {
        nodes {
          ...fluidImage
        }
      }
    }
  `);

  const { presentationWidth } = images[0].childImageSharp.fluid;

  return (
    <CarouselProvider
      infinite
      isPlaying
      interval={3000}
      naturalSlideWidth={presentationWidth}
      naturalSlideHeight={1000}
      totalSlides={images.length}
    >
      <div className="relative shadow-corners">
        <Slider className="border border-white bg-blue z-10">
          {images.map((image, i) => (
            <Slide index={i} key={i}>
              <Link to="/kuchyne-nabytok-na-mieru">
                <Img fluid={image.childImageSharp.fluid} />
              </Link>
            </Slide>
          ))}
        </Slider>
      </div>
      <ButtonBack>←</ButtonBack>
      <ButtonNext>→</ButtonNext>
      <DotGroup className="flex justify-center pt-2" />
    </CarouselProvider>
  );
};
