import React from 'react';
import { Link } from 'gatsby';

import { Layout } from '../components/Layout';
import { Meta } from '../components/Meta';
import { Container } from '../components/Container';
import { Carousel } from '../components/Carousel';

export default () => {
  return (
    <Layout separator={false}>
      <Meta />
      <section className="bg-blue py-6 md:pb-2 text-blue-light relative overflow-hidden shadow-inner">
        <Container className="md:flex">
          <div className="flex-1 pb-6 md:pb-0 md:pl-2 order-last">
            <Carousel />
          </div>
          <div className="flex-1 md:pr-2 flex justify-center items-center flex-col">
            <h2 className="text-3xl font-bold text-blue-lighter mb-2">Ponúkame vám:</h2>
            <ul>
              <li>
                <Link to="/kuchyne-nabytok-na-mieru" className="button button--center">
                  Výroba kuchýň a nábytku na mieru
                </Link>
              </li>
              <li>
                <Link to="/servis-oprava-kuchynskych-liniek" className="button button--center">
                  Servis a oprava kuchynských liniek
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </section>
    </Layout>
  );
};
